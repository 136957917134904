import React from 'react';
import { MorNames } from '../../../instruments/utils';

function MorList(props) {
    const { fieldKey, item } = props;

    return (
        <div>
            { item[ fieldKey ] && item[ fieldKey ].length > 0 ? item[ fieldKey ].map((el, i)=>{
                return (
                    <div
                        data-key = { i }
                        key = { i }>{ `${MorNames[ el.type ]}: ${el.balance}` }
                    </div>
                );
            }) : null }
        </div>
    );
}

export default MorList;
