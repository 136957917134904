import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';


function AccountUsers(props) {
    const listSelect = {
        role: [
            { value: 'admin', label: 'Admin' },
            { value: 'manager', label: 'Manager' },
            { value: 'partner', label: 'partner' },
            { value: 'client', label: 'Client' },
        ] };

    return  (
        <>
            <div className = 'account'>
                <ListConstructor
                    { ...props }
                    isShowAddBtn
                    fields = { listing.list }
                    limit = { 200 }
                    listSelect = { listSelect }
                    sortDefault = 'createdAt'
                    sortOrderDefault = { false }
                    type = { 'user' }
                    urlApi = { '/userList' }
                />
            </div>
        </>

    );
}

export default AccountUsers;
