import React, { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { inputFlexStyles, inputStyles } from '../FormConstructor/style';
import { useMask } from '@react-input/mask';
import { Text } from '../jsx';

export const InputMask = (props) => {
    const {
        edit = '',
        item = null,
        fieldKey = '',
    } = props;
    const [ value, setValue ] = useState('');

    const onMask = (event) => {
        if (event.detail.input && event.detail.isValid) {
            props.onChange(fieldKey, event.detail.value);
        }
    };

    // Маска змінюється в залежності від того, чи є код країни
    const inputRef = useMask({
        mask:        item?.country?.phone ? `(__)___-__-__` : `__(___) ___-__-__`,
        replacement: { _: /\d/ },
        onMask:      onMask,
    });

    useEffect(() => {
        if (item?.[ fieldKey ]) {
            const code = item.country?.phone ? item.country.phone : '';
            setValue(code + item[ fieldKey ]);
        }
    }, [ item ]);

    return item && (
        <Box style = { inputFlexStyles }>
            {edit && edit === item?._id ? (
                <>
                    {/* Якщо є код країни, показуємо його та забираємо з маски */}
                    <TextField
                        disabled = { false }  // Поле не заблоковане
                        inputProps = {{
                            style:          inputStyles,
                            autoComplete:   "off",
                            startAdornment: item.country?.phone ? null : "(...)", // Тільки для відображення
                        }}
                        inputRef = { inputRef }
                        label = { '' }
                        name = { fieldKey }
                        placeholder = { '' }
                        size = 'small'
                        sx = {{ width: '100%' }}
                        type = { 'text' }
                        value = { value }
                        variant = 'outlined'
                    />
                </>
            ) : (
                <>
                    <Text
                        type = { 'copy' }
                        { ...props }
                        isCopy
                        fieldKey = { 'phoneText' }
                        item = {{ phoneText: value }}
                    />
                </>
            )}
        </Box>
    );
};
