// eslint-disable-line
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import  { inputStyles, inputWrapStyles }  from './style';

export const Textarea = (props) => {
    const { valueField, nameField, errors = [],
        setFieldValue, maxHeight } = props;
    const [ value, setValue ] = useState('');
    const  onChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        setFieldValue(nameField, newValue);
    };

    useEffect(() => {
        if (valueField === 'empty!') {
            setValue('');
            setFieldValue(nameField, '');
        } else {
            setValue(valueField);
        }
    }, [ valueField ]);


    let height  = maxHeight > 250 ? maxHeight / 100 : maxHeight;

    return (
        <Box
            className = { 'textArea' }
            sx = { inputWrapStyles }>
            <TextField
                multiline
                error = { !!errors[ nameField ] }
                helperText = { errors[ nameField ] || '' }
                inputProps = {{ autoComplete: "off"  }}
                name = { nameField }
                rows = { height || 4 }
                sx = { inputStyles }
                value = { value }
                onChange = { onChange }
            />
        </Box>
    );
};
