const listing = {};

listing.list = {

    name: {
        type:   'text',
        title:  'Name',
        width:  '10',
        filter: 'Input',
    },
    companyId: {
        type:   'text',
        title:  'Компанія',
        width:  '10',
        filter: 'SearchV2',
    },
    phone: {
        type:  'text',
        title: 'Телефон',
        width: '10',
    },
    price_purchase: {
        type:   'text',
        title:  'Cумму закупки',
        width:  '10',
        filter: 'Input',
    },
    price: {
        type:   'text',
        title:  'Ціна для компанії',
        width:  '10',
        filter: 'Input',
    },
    comment: {
        type:  'text',
        title: 'Коммент',
        width: '10',

    },
    date_active: {
        sort:  true,
        type:  'date',
        title: 'Дата активації',
    },
    createdAt: {
        sort:  true,
        type:  'date',
        title: 'Дата видалення',
    },

};

export { listing };
