import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, IconButton, Modal, Button } from '@mui/material';
import  { modalStyle }  from '../FormConstructor/style';
import { api } from '../../../Api';

export const UploadImage = (props) => {
    const { setFieldValue, valueField, nameField, isListing, field, type, item } = props;
    const [ showModal, setShowModal ] = useState(false);
    const [ upImg, setUpImg ] = useState(null);
    const [ value, setValue ] = useState('');
    const [ file, setFile ] = useState({});


    const deleteImg = () => {
        setFieldValue(nameField, '');
        setUpImg(null);
    };
    useEffect(() => {
        setValue(valueField);
    }, [ valueField ]);

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const src = reader.result.toString();
                setUpImg(src);
            });
            const fileImg = event.target.files[ 0 ];
            if (fileImg && fileImg.name.indexOf('.svg') > -1) {
                setFile(fileImg);
            }
            reader.readAsDataURL(fileImg);
            setShowModal(true);
        }
    };

    async function  generateDownload() {
        if (file &&  file instanceof File) {
            const formData = new FormData();
            formData.append('File', file);
            formData.append('type', type);
            formData.append('_id', item._id);

            const result = await api.postFile(formData, '/saveImage');
            if (result?.result?.[ 0 ]?.url) {
                setFieldValue(nameField,  result?.result?.[ 0 ]?.url);
                setShowModal(false);
            }
        } else if (upImg) {
            const name = field.fieldsName  ? field.fieldsName  : 'file';
            setFieldValue(name,  upImg);
            setShowModal(false);
        }
    }

    return (
        <>
            <Modal
                open = { showModal }
                onClose = { () => setShowModal(false) }>
                <div style = { modalStyle }>
                    <img
                        alt = 'avatar'
                        src = { upImg }
                        style = {{ margin: '0 auto', maxWidth: '100%', maxHeight: '500px' }}
                    />

                    <Box
                        display = 'flex'
                        justifyContent = 'space-between'>
                        <Button
                            color = 'warning'
                            variant = 'contained'
                            onClick = { ()=>{
                                setShowModal(false);
                                setUpImg(null);
                            } }>
                            Cancel
                        </Button>
                        <Button
                            color = 'primary'
                            variant = 'contained'
                            onClick = { ()=>generateDownload() }>
                            Ok
                        </Button>
                    </Box>
                </div>
            </Modal>
            {!isListing ? (
                <div>{value}</div>

            ) : null}
            <Box
                alignItems = { isListing ? 'center' : 'flex-end' }
                backgroundColor = { upImg || value ? '#ccc' : '' }
                display = 'flex'
                justifyContent = { isListing ? 'center' : 'flex-start' }
                padding = { !isListing && (upImg || value) ? '10px' : '0' }
                position = 'relative' >
                <IconButton
                    component = 'label'>
                    <FontAwesomeIcon
                        icon = { faUpload }
                        style = {{ color: 'rgb(25, 118, 210)' }}
                    />
                    <input
                        hidden
                        type = 'file'
                        onChange = { onSelectFile }
                    />
                </IconButton>
                {upImg || value ? (
                    <>
                        {!isListing ? (

                            <img
                                alt = ''
                                src = { upImg || `localhost:3000/${value }` }
                                style = { isListing ? { width: '30px' } : { maxWidth: '100px' } }
                            />
                        ) : null}

                        <IconButton
                            className = 'delete'
                            onClick = { () => deleteImg() }>
                            <FontAwesomeIcon
                                icon = { faTrash }
                                style = {{ color: '#f75353' }}
                            />
                        </IconButton>
                    </>

                ) : null}

            </Box>

        </>

    );
};
