import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';


function AccountService(props) {
    return (
        <div className = 'account'>
            <ListConstructor
                { ...props }
                isShowAddBtn
                fields = { listing.list }
                limit = { 20 }
                sortDefault = 'name'
                sortOrderDefault = { false }
                type = { 'service' }
                urlApi = { '/service' }
            />
        </div>
    );
}

export default AccountService;
