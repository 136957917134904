import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { api } from '../../../Api';
import  { searchListStyles, closeButtonStyles, listItemStyles, listItemHoverStyles, inputWrapStyles, inputStyles }  from './style';
import debounce from 'lodash.debounce'; // Імпорт debounce

export const Search = (props) => {
    const { valueField = '', nameField, setFieldValue, type = 'author', placeholder = '', multi = false, label = '', clear = false, dataSearch } = props;
    const [ list, setList ] = useState([]);
    const [ val, setVal ] = useState(valueField);
    const [ isOpen, setIsOpen ] = useState(false);
    const searchRef = useRef(null);

    const setValue = (item) => {
        const name = multi ? item.label : nameField;
        setFieldValue(name, item.value);
        if (multi) {
            setVal('');
        } else {
            setVal(item.label);
        }
        setList([]);
        setIsOpen(false);
    };

    const getData = async (value) => {
        const param = {
            search: value,
            type:   type,
            ...dataSearch,
        };
        const data = await api.postData(param, '/getListSearch');
        if (data) {
            setList(data);
            setIsOpen(true);
        }
    };

    useEffect(() => {
        if (!valueField) {
            setVal('');
        }
    }, [ valueField ]);

    useEffect(() => {
        if (clear) {
            setVal('');
        }
    }, [ clear ]);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setVal(inputValue);
    };

    const debouncedGetData = useRef(debounce((value) => {
        if (value.length >= 2) {
            getData(value);
        }
    }, 400)).current; // Використовуємо debounce для відправки запиту з затримкою

    useEffect(() => {
        if (val !== valueField && isOpen) {
            debouncedGetData(val); // Викликаємо debouncedGetData замість getData
        }
    }, [ val ]);

    const handleClick = () => {
        getData('');
    };

    const handleListClick = (item) => () => {
        setValue(item);
    };

    const handleClose = () => {
        setIsOpen(false);
        if (valueField) {
            setFieldValue(nameField, '');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const listJSX = isOpen && list && list.length > 0 ? (
        <Box sx = { searchListStyles }>
            {list.map((item, index) => (
                <Box
                    key = { index }
                    style = {{
                        ...listItemStyles,
                        ...item.hover && listItemHoverStyles,
                    }}
                    onClick = { handleListClick(item) }
                    onMouseEnter = { () => {
                        const updatedList = [ ...list ];
                        updatedList[ index ].hover = true;
                        setList(updatedList);
                    } }
                    onMouseLeave = { () => {
                        const updatedList = [ ...list ];
                        updatedList[ index ].hover = false;
                        setList(updatedList);
                    } }>
                    {item.label}
                </Box>
            ))}
        </Box>
    ) : null;

    return (
        <Box
            ref = { searchRef }
            style = { inputWrapStyles  }>
            <TextField
                inputProps = {{ autoComplete: "off"  }}
                name = 'search'
                placeholder = { placeholder || label }
                size = 'small'
                sx = { inputStyles }
                type = 'text'
                value = {  val }
                variant = 'outlined'
                onChange = { handleChange }
                onClick = { handleClick }
            />
            {listJSX && (
                <>
                    <IconButton
                        style = { closeButtonStyles }
                        onClick = { handleClose }>
                        <FontAwesomeIcon
                            icon = { faTimes }
                            style = {{ height: 11 }}
                        />
                    </IconButton>
                    {listJSX}
                </>
            )}
        </Box>
    );
};
