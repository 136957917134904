const menuItems = [

    {
        title: 'Користувачі',
        key:   'users',
        roles: [  'admin' ],
    },
    {
        title: 'Компанії',
        key:   'company',
        roles: [ 'manager', 'admin' ],

    },
    {
        title: 'Номери',
        key:   'phone',
        roles: [ 'manager', 'admin' ],
    },
    {
        title: 'Постачальники',
        key:   'postman',
        roles: [ 'admin' ],
    },
    {
        title: 'Аналітика',
        key:   'analytics',
        roles: [ 'manager', 'admin' ],
    },
    {
        title: 'Статистика дзвінків',
        key:   'calls',
        roles: [ 'client', 'manager', 'admin' ],
    },
    {
        title: 'Компанії',
        key:   '/home',
        roles: [ 'client' ],
    },
    {
        title: 'Послуги',
        key:   'service',
        roles: [ 'admin' ],
    },
    {
        title: 'Історія послуги',
        key:   'serviceCompany',
        roles: [ 'client', 'manager', 'admin' ],
    },
    // {
    //     title: 'Enhancement',
    //     key:   'copyModule',
    // },
];


export const list = {
    menuItems: menuItems,
};
