import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import Import from '../Import';


function AccountCalls(props) {
    return (
        <div className = 'account'>
            <div style = {{ margin: '20px' }}>
                <Import
                    { ...props }
                    apiUrl =  { `/import_mor` }
                    name = 'Import mor'
                    type = { 'phone' }
                />
            </div>

            <ListConstructor
                { ...props }
                isShowAddBtn
                fields = { listing.list }
                limit = { 200 }
                sortDefault = 'calldate'
                sortOrderDefault = { false }
                type = { 'calls' }
                urlApi = { '/calls' }
            />
        </div>
    );
}

export default AccountCalls;
