import React, { useEffect, useState } from 'react';

import { Text, DateTxt, Link, Img, BtnAll } from '../jsx';
import * as fieldForm from '../FormConstructor/export';

function ItemRender(props) {
    const { field, item, onChange, defaultItem, fieldKey, edit, setItem,
        listSelects, CustomComponents = null, dataSearch, setDataSearch, userData } = props;
    const [ contentJSX, setContentJSX ] = useState('');

    useEffect(() => {
        if (field.dataSearch) {
            for (const el of Object.keys(field.dataSearch)) {
                const val = item && item[ el ] ? item[ el ] : '';
                if (val) {
                    dataSearch[ el ] = val;
                }
            }
            setDataSearch(dataSearch);
        }
    }, [ item, field ]);

    useEffect(() => {
        let permissionEdit = false;
        if (field.component && edit === item._id
            &&  (!field.onlyNew || item._id === 'new')) {
            if (!field.role_edit) {
                permissionEdit = true;
            }
            if (field.role_edit && field.role_edit.includes(userData.role)) {
                permissionEdit = true;
            }
        }


        let text = '';

        if (typeof item[ fieldKey ] === 'undefined') {
            item[ fieldKey ] = typeof defaultItem[ fieldKey ] !== 'undefined' ? defaultItem[ fieldKey ] : '';
        }


        if (permissionEdit) {
            const Component = fieldForm[ field.component ]
                ? fieldForm[ field.component ] : null;

            if (Component) {
                text = (
                    <Component
                        { ...props }
                        isListing
                        isTime
                        dataSearch = { dataSearch }
                        edit = { edit }
                        index = { edit }
                        key = { fieldKey }
                        labelTxt = 'seo_description'
                        list = { listSelects && listSelects[ fieldKey ]
                            ? listSelects[ fieldKey ]
                            : [] }
                        nameField = { fieldKey  }
                        placeholder = { field.title || '' }
                        setFieldValue = { onChange }
                        setItem = { setItem }
                        showTranslate = { false }
                        type = { field.typeSearch ? field.typeSearch : fieldKey }
                        valueField = { item?.[ fieldKey ]  || '' }
                    />
                );
            }
        } else {
            try {
                switch (field.type) {
                    case 'text':
                    case 'copy':
                        text = (
                            <>
                                <Text
                                    listSelects = { listSelects }
                                    type = { field.type }
                                    { ...props }
                                    isCopy = { field.type === 'copy' }
                                    item = { props.item }
                                />
                            </>

                        );

                        break;
                    case 'arrSelect':
                        text = (
                            <>
                                {props.item[ fieldKey ] && props.item[ fieldKey ].length > 0
                                    ? props.item[ fieldKey ].map((el, index) => (
                                        <React.Fragment key = { index }>
                                            <Text
                                                type = { field.type }
                                                { ...props }
                                                item = {{ [ fieldKey ]: el && (el.label || el.lang) ? (el.label || el.lang) + ' ' : '' }}
                                            />
                                            <br/>
                                        </React.Fragment>
                                    ))
                                    : (
                                        <Text
                                            type = { field.type }
                                            { ...props }
                                            isArr
                                            item = { props.item }
                                        />
                                    )}
                            </>
                        );

                        break;

                    case 'img':
                    case 'image':

                        text = (
                            <Img
                                { ...props }
                            />
                        );
                        break;
                    case 'modal':
                        text = item[ fieldKey ] && item[ fieldKey ].length > 0 ? item[ fieldKey ].length + ' ' : 'none';
                        break;
                    case 'custom':
                        if (CustomComponents && CustomComponents[ fieldKey ]) {
                            const CustomComponent = CustomComponents[ fieldKey ];
                            text = (
                                <CustomComponent
                                    { ...props }
                                    dataSearch = { dataSearch }
                                    edit = { edit }
                                    field = { fieldKey }
                                    item = { item }
                                    listSelects = { listSelects }
                                    onChange = { onChange }
                                />
                            );
                        } else {
                            text = '';
                        }
                        break;
                    case 'date':
                        text = (
                            <DateTxt
                                { ...props }
                                field = { fieldKey }
                            />
                        );
                        break;
                    case 'link':
                        if (typeof item[ fieldKey ] === 'string') {
                            text = (
                                <Link
                                    link = { field.link ? `${field.link}${item._id}` : item[ fieldKey ] }
                                    text = { item[ fieldKey ] }
                                    { ...props }
                                />
                            );
                        } else {
                            text = item[ fieldKey ] ? (
                                <Link
                                    link = { item[ fieldKey ].link }
                                    text = { item[ fieldKey ].text }
                                    { ...props }
                                />
                            ) : '';
                        }

                        break;
                    case 'checkbox':
                        text = item._id ? (
                            <fieldForm.InputCheckbox
                                { ...props }
                                edit = { false }
                                key = { fieldKey }
                                nameField = { fieldKey }
                                setFieldValue = { onChange }
                                valueField = { item[ fieldKey ] ? !!item[ fieldKey ] : false }
                            />

                        ) : '';
                        break;
                    case 'delete':
                        text = item._id ? (
                            <BtnAll
                                { ...props }
                                field = {{ ...field, value: fieldKey }}
                            />
                        ) : '';
                        break;
                    default: text = '';
                }
            } catch (error) {
                console.log(error);
            }
        }

        setContentJSX(text);
    }, [ item ]);


    return contentJSX;
}

export default ItemRender;
