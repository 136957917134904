const listing = {};

listing.list = {
    country: {
        type:      'text',
        title:     'Країна',
        width:     '5',
        filter:    'SearchV2',
        component: 'SearchV2',
        require:   true,
    },
    phone: {
        type:      'copy',
        title:     'Телефон',
        width:     '15',
        filter:    'Input',
        component: 'Input',
    },
    type: {
        type:   'text',
        title:  'Тип',
        width:  '15',
        filter: 'Select',
    },
    postman: {
        type:      'text',
        title:     'Постачальник',
        width:     '15',
        component: 'SearchV2',
        filter:    'SearchV2',
    },
    companyId: {
        type:   'text',
        title:  'компанія',
        width:  '15',
        filter: 'SearchV2',
    },
    price_postman: {
        type:      'text',
        title:     'Ціна постачальника',
        width:     '10',
        component: 'Input',
        filter:    'Input',

    },
    price_postman_subscription: {
        type:      'text',
        title:     'Абонплата постачальника',
        width:     '10',
        component: 'Input',
        filter:    'Input',

    },
    price: {
        type:      'text',
        title:     'Ціна для компанії',
        width:     '10',
        component: 'Input',
        filter:    'Input',
    },
    price_subscription: {
        type:      'text',
        title:     'Абонплата для компанії',
        width:     '10',
        component: 'Input',
        filter:    'Input',
    },
    status: {
        type:      'checkbox',
        title:     'Статус',
        width:     '5',
        component: 'InputCheckbox',
    },

    createdAt: {
        sort:  true,
        type:  'date',
        title: 'Дата створення',
        width: '5',
    },
    delete: {
        type:   'delete',
        values: [ 'delete' ],
        width:  '5',
    },
};

export { listing };
