import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import Phone from '../Phone';
export const PhoneRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <Phone
                        { ...props }
                    />
                }
                path = { book.admin.phone }
            />
        </Routes>
    );
};
