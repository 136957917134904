import React, { useEffect, useState } from 'react';
import Single from '../Account/Company/single';
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { tabsStyles, tabStyles } from '../Account/ListConstructor/style';

const selectState = (state) => ({
    userData: state.swapi.userData,
});

function Home () {
    const state = useSelector(selectState);
    const  userData  = state.userData;
    const [ company_id, setCompanyId ] = useState('');
    console.log(userData);
    useEffect(()=>{
        if (userData.role === 'client' && userData.company_id?.length > 0) {
            setCompanyId(userData.company_id[ 0 ]._id);
        }
    }, [ userData ]);

    const listJSX = userData.role === 'client' && userData.company_id?.length > 0 ? userData.company_id.map((company)=>{
        return (
            <Tab
                key = { company._id }
                label = { company.name }
                sx = { tabStyles }
                value = { company._id }
            />
        );
    }) : null;

    return listJSX && company_id && (
        <>
            <Tabs
                aria-label = 'nav tabs'
                sx = { tabsStyles }
                value = { company_id }

                onChange = { (event, newValue) => setCompanyId(newValue) }>
                {listJSX}
            </Tabs>
            <Single company_id = { company_id } />
        </>
    );
}

export default Home;
