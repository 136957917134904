import React from 'react';
import { MorNames } from '../../../instruments/utils';

function Mor(props) {
    const { fieldKey, item } = props;

    return (
        <div>
            { MorNames[ item[ fieldKey ] ] }
        </div>
    );
}

export default Mor;
