const listing = {};

listing.list = {
    name: {
        type:      'link',
        title:     'Назва',
        width:     '10',
        filter:    'Input',
        link:      '/admin/company/',
        component: 'Input',
        role_edit: [ 'admin' ],
    },
    mor_type: {
        type:      'custom',
        title:     'Тип',
        width:     '5',
        filter:    'Select',
        component: 'Select',
    },
    atc_type: {
        type:      'custom',
        title:     'АТС',
        width:     '5',
        filter:    'Select',
        component: 'Select',
    },
    // type_compamy: {
    //     type:      'text',
    //     title:     'Тип',
    //     width:     '5',
    //     filter:    'Select',
    //     component: 'Select',
    //     roles:     [ 'admin' ],

    // },
    managerId: {
        type:      'text',
        title:     'Менеджер',
        width:     '10',
        component: 'SearchV2',
        filter:    'SearchV2',
        roles:     [ 'admin' ],
    },

    status: {
        type:      'checkbox',
        title:     'Статус',
        width:     '3',
        component: 'InputCheckbox',
    },
    credit: {
        type:      'text',
        title:     'Кредитний лімит',
        width:     '3',
        component: 'Input',
    },
    joiny_balance: {
        type:      'checkbox',
        title:     'Спільний баланс',
        width:     '3',
        component: 'InputCheckbox',
    },
    balance: {
        type:      'custom',
        title:     'Баланс послуг',
        width:     '10',
        sort:      true,
        role_edit: [ 'admin' ],
    },
    balance_phone: {
        type:      'custom',
        title:     'Баланс телефонії',
        width:     '15',
        sort:      true,
        role_edit: [ 'admin' ],
    },
    mor_ids: {
        type:  'custom',
        title: 'MOR List',
        width: '10',
    },
    next_pay: {
        type:  'text',
        title: 'Дата платежу',
        width: '5',
        sort:  true,
    },
    survisorId: {
        type:      'text',
        title:     'Представник',
        width:     '5',
        component: 'SearchV2',
        filter:    'SearchV2',
        roles:     [ 'admin' ],
    },
    partner: {
        type:      'text',
        title:     'Партнер',
        width:     '10',
        component: 'SearchV2',
        filter:    'SearchV2',
        roles:     [ 'admin' ],
    },
    partnerPercent: {
        type:      'text',
        title:     'Процент партнера',
        width:     '5',
        component: 'Input',
        roles:     [ 'admin' ],

    },
    survisorPercent: {
        type:      'text',
        title:     'Процент представника',
        width:     '5',
        component: 'Input',
        roles:     [ 'admin' ],

    },

    createdAt: {
        type:  'date',
        title: 'дата створення',
        width: '5',
    },
    delete: {
        type:   'delete',
        values: [ 'edit', 'delete' ],
        width:  '5',
    },
};

export { listing };
