import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { useSelector } from 'react-redux';
import moment from 'moment';

const selectState = (state) => ({
    userData: state.swapi.userData,
});


function AccountAnalytics(props) {
    const state = useSelector(selectState);

    const  userData  = state.userData;

    const filter = {

        from: moment().date(1)
            .hour(0)
            .minute(0)
            .second(0),
    };

    if (userData.role !== 'admin') {
        filter.managerId = {
            value: userData._id,
            label: userData.name,
        };
    }
    console.log(filter, userData, 'userData');

    return (
        <div className = 'account'>
            <ListConstructor
                { ...props }
                isDefaultFilter
                fields = { listing.list }
                filterOutside = { filter }
                limit = { 200 }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'analytics' }
                urlApi = { '/analytics' }
            />
        </div>
    );
}

export default AccountAnalytics;
