// eslint-disable-line
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Main } from './components/Main';
//import SocketInit from './Api/socket';
import { store } from './init';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store = { store }>
        <Main />

    </Provider>,
);
