import React, { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { inputWrapStyles, inputStyles, lengthText } from './style';

export const Input = (props) => {
    const {
        valueField = '',
        nameField,
        setFieldValue,
        placeholder,
        label = '',
        clear,
        index = '',
        isNumber = false,
        disabled = false,
        showLength = false,
    } = props;

    const [ value, setValue ] = useState(valueField);

    useEffect(() => {
        if (clear) {
            setValue('');
        }
    }, [ clear ]);

    useEffect(() => {
        if (valueField === 'empty!') {
            setValue('');
            setFieldValue(nameField, '', index);
        } else if (valueField !== value) {
            setValue(valueField);
        }
    }, [ valueField ]);

    const onChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        setFieldValue(nameField, newValue, index);
    };

    return (
        <Box style = { inputWrapStyles }>
            {showLength && value.length > 0 ? (
                <Box sx = { lengthText }>{value.length}</Box>
            ) : null}
            <TextField
                disabled = { disabled }
                inputProps = {{ style: inputStyles, autoComplete: "off" }}
                label = { label }
                name = { nameField }
                placeholder = { placeholder }
                size = 'small'
                sx = {{ width: '100%' }}
                title = { label ? label : placeholder }
                type = { isNumber ? 'number' : 'text' }
                value = { value }
                variant = 'outlined'
                onChange = { onChange }
            />
        </Box>
    );
};
