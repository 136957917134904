// Instruments
import { types } from './types';

const initialState = {
    isLogged:   false,
    isFetching: false,
    userData:   {},

};

export const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_DATA: {
            return {
                ...state,
                userData: action.payload,
            };
        }
        case types.SET_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.payload,
            };
        }
        case types.SET_IS_LOGGED: {
            return {
                ...state,
                isLogged: action.payload,
            };
        }
        default:
            return state;
    }
};
