import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';


function AccountServiceCompany(props) {
    return (
        <div className = 'account'>
            <ListConstructor
                { ...props }
                fields = { listing.list }
                limit = { 20 }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'serviceCompany' }
                urlApi = { '/serviceCompany' }
            />
        </div>
    );
}

export default AccountServiceCompany;
