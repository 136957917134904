import React from 'react';
import { Box } from '@mui/material';

const imgStyles = {
    maxWidth:  '30px',
    maxHeight: '30px',
};
const imgWrapStyles = {
    backgroundColor: '#ccc',
    margin:          '0 auto',
    width:           '30px',
    height:          '30px',
    display:         'flex',
    justifyContent:  'center',
    alignItems:      'center',
};


export const Img = (props) => {
    const { fieldKey, item } = props;
    const domain = item[ fieldKey ] && item[ fieldKey ].indexOf('https') > -1 ? '' : '';

    return  item[ fieldKey ] ? (
        <Box sx = { imgWrapStyles }>
            <img
                alt = ''
                src = { domain + item[ fieldKey ] }
                style = { imgStyles }
            />
        </Box>
    ) : '';
};
