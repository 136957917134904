import React, { useState, useEffect } from 'react';
import { Modal, Box } from '@mui/material';
import { modalSmallStyle, modalStyle } from '../FormConstructor/style';

export const ModalResult = ({ isBig = false, result = {}, isSend = false, setSending }) => {
    const [ open, setOpen ] = useState(0);
    const [ text, setText ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ color, setColor ] = useState('');

    const handleClose = () => {
        setOpen(false);
        setText('');
        setTitle('');
        setSending(false);
    };

    useEffect(() => {
        if (open) {
            const timer = setTimeout(function() {
                handleClose();
            }, open);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [ open ]);

    useEffect(() => {
        if (isSend) {
            setSending(isSend);
            if (result.success) {
                setOpen(2000);
                setTitle('Success');
                setColor('green');
            } else if (result.error) {
                setOpen(12000);
                setTitle('Error');
                setColor('red');
            } else {
                setOpen(12000);
                setTitle('PLEASE WAIT!');
                setColor('green');
            }
            setText(result.error || result.success || '');
        }
    }, [ isSend, result.error, result.success ]);


    return (
        <Modal
            open = { open > 0 }
            onClose = { handleClose }>
            <Box sx = { isBig ? modalStyle : modalSmallStyle }>
                <h2 style = {{ color: color }}>{title}</h2>
                {text}
            </Box>
        </Modal>
    );
};
