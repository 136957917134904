// eslint-disable-line
import React, { useEffect, useState } from 'react';
import * as fieldForm from '../FormConstructor/export';

// eslint-disable-line

function ListFilter(props) {
    const { fields, name, list, filter, setFilter, setCurrentPage, label } = props;
    const [ dataSearch, setDataSearch ] = useState({});
    const  onChange = (name, val) => {
        if (Object.keys(dataSearch).length > 0) {
            const clearFilter = { ...filter };
            for (const el of Object.keys(dataSearch)) {
                delete clearFilter[ el ];
            }
        }
        if (val) {
            filter[ name ] = val;
        } else {
            delete filter[ name ];
        }
        setCurrentPage(1);
        setFilter({ ...filter });
    };
    useEffect(() => {
        if (fields[ name ].dataSearch) {
            for (const el of Object.keys(fields[ name ].dataSearch)) {
                const val = filter && filter[ el ] ? filter[ el ] : '';
                if (val &&  dataSearch[ el ] !== val) {
                    delete filter[ name ];

                    setFilter({ ...filter });
                    dataSearch[ el ] = val;
                }
            }
            setDataSearch(dataSearch);
        }
    }, [ filter ]);

    const Component = fieldForm[ fields[ name ].filter ];
    if (fields[ name ].filter  === 'DateField') {
        let nameFieldFrom = 'from';
        let nameFieldTo = 'to';
        if (name === 'start_date') {
            nameFieldFrom = 'start_date_from';
            nameFieldTo = 'start_date_to';
        }

        return Component ? (
            <>
                <Component
                    { ...props }
                    isTime
                    label = { label + ' from' }
                    list = { list }
                    nameField = { nameFieldFrom }
                    setFieldValue = { onChange }
                    valueField = { filter && filter.from ? filter.from : '' }


                />
                <Component
                    { ...props }
                    isTime
                    label = { label + ' to' }
                    list = { list }
                    nameField = { nameFieldTo  }
                    setFieldValue = { onChange }
                    valueField = { filter && filter.to ? filter.to : '' }

                />
            </>

        ) : null;
    }

    return Component ? (
        <Component
            edit
            isFilter
            dataSearch = { dataSearch }
            label = { label }
            list = { list }
            nameField = { name }
            setFieldValue = { onChange }
            valueField = {  filter && filter[ name ] ? filter[ name ] : '' }
            { ...props }
        />
    ) : null;
}

export default ListFilter;
