import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import { api } from '../../../Api';

export const SelectedStatusAll = (props) => {
    const { item } = props;
    const [ status, setStatus ] = useState(true);
    const [ open, setOpen ] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async (confirmed) => {
        setOpen(false);
        if (confirmed) {
            setStatus((prevStatus) => !prevStatus);
            await api.postData({ status: !status, filter: { postman: item._id } }, '/phone/selectedAllStatus');
        }
    };

    return (
        <Stack
            direction = 'row'
            spacing = { 2 }>
            <Switch
                checked = { status }
                onChange = { handleClickOpen }
            />

            <Dialog
                open = { open }
                onClose = { () => handleClose(false) }>
                <DialogTitle>{"Підтвердіть дію"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ви впевнені, що хочете {status ? 'виключити' : 'включити'} статус?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color = 'primary'
                        onClick = { () => handleClose(false) }>
                        Скасувати
                    </Button>
                    <Button
                        autoFocus
                        color = 'primary'
                        onClick = { () => handleClose(true) }>
                        Підтвердити
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

