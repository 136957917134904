import React, { useState } from 'react';
import { Modal, Button, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as fieldForm from './export';
import  { modalStyle, stylePencil }  from './style';
import EditIcon from '@mui/icons-material/Edit';


export const ModalComponent = (props) => {
    const { nameField, setFieldValue, field, item, listSelects, CustomComponents } = props;
    const [ isModalOpen, setModalOpen ] = useState(false);
    const [ data, setData ] = useState(item[ field.value ] || []);
    const deleteItem = (i) => {
        if (data && data.length > 0) {
            const newData = data.filter((el, k) => k !== i);
            setData(newData);
        }
    };

    const setChangeData = (name, val) => {
        let newData = [ ...data ];
        if (name) {
            const param = name.split('|');
            if (newData[ param[ 0 ] ]) {
                newData[ param[ 0 ] ][ param[ 1 ] ] = val;
            } else {
                newData[ param[ 0 ] ] = {
                    [ param[ 1 ] ]: val,
                };
            }

            setData(newData);
        }
    };

    const add = () => {
        const newData =  [ ...data ];
        const newItem = {};
        if (field && field.values && field.value.length > 0) {
            for (const a of field.values) {
                newItem[ a.field ] = '';
            }
            newData.push(newItem);
            setData(newData);
        }
    };

    const save = () => {
        setModalOpen(false);
        setFieldValue(nameField, data);
    };

    const dataJSX = data && data.length > 0 ? data.map((itemV, i) => {
        const fieldsJSX = field && field.values && field.values.length > 0
            ? field.values.map((f) => {
                const Component = fieldForm[ f.component ] ? fieldForm[ f.component ] : '';
                let width = 100 / field.values.length;
                if (f.component === 'InputCheckbox') {
                    width = '5%';
                }
                let dataSearch = {};
                if (f.dataSearch) {
                    for (const el of Object.keys(f.dataSearch)) {
                        const val = item && item[ el ] ? item[ el ] : '';
                        if (val) {
                            dataSearch[ el ] = val;
                        }
                    }
                }
                if (f.type === 'custom' && CustomComponents && CustomComponents[ f.field ]) {
                    const CustomComponent = CustomComponents[ f.field  ];

                    return (
                        <Box
                            key = { i }>
                            <CustomComponent
                                field = { f }
                                fieldParent = { field }
                                index = { i }
                                item = { item }
                            />
                        </Box>
                    );
                }

                return Component ? (
                    <Box sx = {{ width: width + "%", marginRight: '10px' }}>
                        <Component
                            { ...props }
                            dataSearch = { dataSearch }
                            key = { i + '|' + f.field }
                            list = { listSelects && listSelects[ f.field ]
                                ? listSelects[ f.field ] : [] }
                            nameField = { i + '|' + f.field }
                            placeholder = { f.field }
                            setFieldValue = { setChangeData }
                            type = { f.typeSearch ? f.typeSearch : f.field }
                            valueField = { itemV[ f.field ] ? itemV[ f.field ] : '' }
                        />
                    </Box>
                ) : <span>{f.text}<br/>{itemV[ f.field ]}</span>;
            })
            : null;

        return (
            <Box
                alignItems = 'center'
                display = 'flex'
                justifyContent = 'space-between'
                key = { i } // Додав key до кореневого елемента JSX
                margin = '10px auto'>
                {fieldsJSX}
                <div onClick = { () => deleteItem(i) }>
                    <FontAwesomeIcon
                        icon = { faTrash }
                        style = {{ color: 'red', marginLeft: 10 }}
                    />
                </div>
            </Box>
        );
    }) : null;


    return (
        <>
            <div onClick = { () => setModalOpen(true) }>
                {item[ nameField ] && item[ nameField ].length > 0 ? (
                    <div>
                        <EditIcon sx = { stylePencil }/>
                        {item[ nameField ] && item[ nameField ].length} {field.title}
                    </div>
                )  : 'add'}
            </div>
            <Modal
                open = { isModalOpen }
                onClose = { () => setModalOpen(false) }>
                <div style = { modalStyle }>
                    <div
                        style = {{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                        onClick = { () => setModalOpen(false) }>
                        <FontAwesomeIcon icon = { faTimes } />
                    </div>
                    {dataJSX}
                    <div style = {{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button
                            color = 'primary'
                            variant = 'contained'
                            onClick = { add }>
                            Add
                        </Button>
                        <Button
                            color = 'primary'
                            variant = 'contained'
                            onClick = { save }>
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
