import React from 'react';
import { Link as MuiLink } from '@mui/material';

export const Link = (props) => {
    const { link, text } = props;

    return link ? (
        <MuiLink
            href = { link }
            rel = 'noopener noreferrer'
            sx = {{ textDecoration: 'none' }}>
            {text}
        </MuiLink>
    ) : '';
};
