// eslint-disable-line
import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import Import from '../Import';
import { SelectedStatusAll } from './selectedStatusAll';

function Postman(props) {
    const CustomComponents = {
        selectedStatusAll: SelectedStatusAll,
    };

    return (
        <div className = 'account'>
            <div style = {{ margin: '20px' }}>
                <Import
                    { ...props }
                    apiUrl =  { `/import_mor` }
                    name = 'Import mor'
                    type = { 'postman' }
                />
            </div>
            <ListConstructor
                { ...props }
                isShowAddBtn
                sortOrderDefault
                CustomComponents = { CustomComponents }
                fields = { listing.list }
                limit = { 200 }
                sortDefault = 'name'
                type = { 'postman' }
                urlApi = { '/postman' }

            />

        </div>
    );
}

export default Postman;
