// eslint-disable-line
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../Api';
import  FormConstructor  from '../FormConstructor';
import  { Balance_history }  from './balance_history';
import  { Balance }  from './balance';
import { useParams } from 'react-router-dom';
import  { Phones }  from './phones';
import  { BtnService }  from './btn_service';

const selectState = (state) => ({
    userData: state.swapi.userData,
});
function Single(props) {
    let { c_id } = useParams();
    let { company_id } = props;
    const state = useSelector(selectState);
    const  userData  = state.userData;
    const [ data, setData ] = useState(null);
    const [ id, setId ] = useState(0);
    const [ listSelect, setListSelect ] = useState({});

    const CustomComponents = {
        balance_history:       Balance_history,
        balance_phone_history: Balance_history,
        balance:               Balance,
        balance_phone:         Balance,
        phones:                Phones,
        btnService:            BtnService,

    };

    const fields = {
        name: {
            type:      'text',
            label:     'Назва',
            width:     '85',
            component: 'Input',
            onlyRead:  userData.role !== 'admin',
        },
        empty: {
            type:  'empty',
            width: '10',
        },
        phones: {
            type:  'custom',
            title: 'Номери',
            width: '100',
        },

        service: {
            byLang:    true,
            type:      'text',
            component: 'ListArr',
            label:     'Послуги',
            list:      [
                {
                    field:     'name',
                    label:     'тип',
                    component: 'Select',
                    width:     '20',
                    list:      listSelect.services,
                    onlyRead:  userData.role !== 'admin',
                    roles:     [ 'admin' ],


                },
                {
                    field:     'comment',
                    label:     'Назва',
                    component: 'Input',
                    width:     '20',
                    onlyRead:  userData.role !== 'admin',

                },
                {
                    field:     'price_purchase',
                    label:     'сумму закупки',
                    component: 'Input',
                    width:     '7',
                    roles:     [ 'admin' ],

                },
                {
                    field:     'price',
                    label:     'Сумма оплати за місяць',
                    component: 'Input',
                    width:     '7',
                    onlyRead:  userData.role !== 'admin',

                },
                {
                    field:     'date_active',
                    label:     'дата активації (перше списання)',
                    component: 'DateField',
                    width:     '10',
                    props:     { format: 'dd.MM.yyyy', beforeAllow: 30 },
                    onlyRead:  userData.role === 'admin',

                },
                {
                    field:     'next_pay',
                    label:     'дата наступного списання',
                    component: 'DateField',
                    width:     '10',
                    props:     { format: 'dd.MM.yyyy', beforeAllow: 30 },
                    onlyRead:  userData.role !== 'admin',

                },
                {
                    type:      'custom',
                    field:     'btnService',
                    label:     'Списати',
                    component: 'btnService',
                    width:     '10',
                    roles:     [ 'admin' ],


                },
                {
                    field:     'status',
                    label:     'Статус',
                    component: 'InputCheckbox',
                    width:     '5',
                    roles:     [ 'admin' ],


                },
            ],
        },
        balance: {
            type:      'custom',
            label:     'Баланс послуг',
            width:     '45',
            component: 'Input',
            onlyRead:  userData.role !== 'admin',
        },
        balance_phone: {
            type:      'custom',
            label:     'Баланс телефонії',
            width:     '45',
            component: 'Input',
            onlyRead:  userData.role !== 'admin',

        },
        balance_history: {
            type:  'custom',
            label: 'Історія балансу послуг',
            width: '45',

        },
        balance_phone_history: {
            type:  'custom',
            label: 'Історія балансу телефонії',
            width: '45',
        },
    };
    const getData = async (id) => {
        setData(null);
        const data = await api.postData({ type: 'company' }, '/company/' + id);
        if (data && data.selectedList) {
            setListSelect(data.selectedList);
        }
        if (data && data.data) {
            setData(data.data);
        }
        if (id === 'new') {
            const defaultData = {};
            setData(defaultData);
        }
    };
    useEffect(() => {
        const props_id = userData?.role === 'client' &&  company_id ? company_id : c_id;
        setId(props_id);
        getData(props_id);
        // eslint-disable-next-line
    }, [ userData.role, c_id, company_id ]);


    return data && Object.keys(data).length > 0 ? (
        <FormConstructor
            { ...props }
            CustomComponents = { CustomComponents }
            data = { data }
            fields = { fields }
            listSelect = { listSelect }
            type = { 'company' }
            urlApi = { '/company/save/' + id }
            userData = { userData }
        />
    ) : null;
}

export default Single;
