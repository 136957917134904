// Types
import { types } from './types';

export const apiActions = {
    fetchContent: (data) => ({
        type:    types.FETCH_CONTENT,
        payload: data,
    }),
    fetchMatchDayBets: (data) => ({
        type:    types.FETCH_MATCH_DAY_BETS,
        payload: data,
    }),
    fetchTagCloud: (data) => ({
        type:    types.FETCH_TAG_CLOUD,
        payload: data,
    }),
    fetchAdv: (state) => ({
        type:    types.FETCH_ADV,
        payload: state,
    }),
    fillAdv: (state) => ({
        type:    types.FILL_ADV,
        payload: state,
    }),
    fillConfig: (data) => ({
        type:    types.FILL_CONFIG,
        payload: data,
    }),
    fillApiData: (data) => ({
        type:    types.FILL_API_DATA,
        payload: data,
    }),
    fillSocket: (data) => ({
        type:    types.FILL_SOCKET,
        payload: data,
    }),
    fillSocketV2: (data) => ({
        type:    types.FILL_SOCKET_V2,
        payload: data,
    }),
    fillSocketID: (data) => ({
        type:    types.FILL_SOCKET_ID,
        payload: data,
    }),
    setRoomId: (data) => ({
        type:    types.SET_ROOM_ID,
        payload: data,
    }),
    fillGames: (data) => ({
        type:    types.FILL_GAMES,
        payload: data,
    }),
    fillBanners: (data) => ({
        type:    types.FILL_BANNERS,
        payload: data,
    }),
    fillContent: (data) => ({
        type:    types.FILL_CONTENT,
        payload: data,
    }),
    fillSeo: (data) => ({
        type:    types.FILL_SEO,
        payload: data,
    }),
    fillMatchDayBets: (data) => ({
        type:    types.FILL_MATCH_DAY_BETS,
        payload: data,
    }),
    fillMatchDay: (data) => ({
        type:    types.FILL_MATCH_DAY,
        payload: data,
    }),
    fillBookmakers: (data) => ({
        type:    types.FILL_BOOKMAKERS,
        payload: data,
    }),
    fillTagCloud: (data) => ({
        type:    types.FILL_TAG_CLOUD,
        payload: data,
    }),
    setIsFetching: (state) => ({
        type:    types.SET_IS_FETCHING,
        payload: state,
    }),
    setIsLogged: (state) => ({
        type:    types.SET_IS_LOGGED,
        payload: state,
    }),
    setIsDb: (state) => ({
        type:    types.SET_IS_LOGGED,
        payload: state,
    }),
    setNotFound: (state) => ({
        type:    types.SET_NOT_FOUND,
        payload: state,
    }),
    setIsHome: (state) => ({
        type:    types.SET_IS_HOME,
        payload: state,
    }),
    setIsApi: (state) => ({
        type:    types.SET_IS_API,
        payload: state,
    }),
    setIsMobile: (state) => ({
        type:    types.SET_IS_MOBILE,
        payload: state,
    }),
    setShowAdv: (state) => ({
        type:    types.SET_SHOW_ADV,
        payload: state,
    }),

    setActiveMenu: (state) => ({
        type:    types.SET_ACTIVE_MENU,
        payload: state,
    }),
    setGameSlug: (state) => ({
        type:    types.SET_GAME_SLUG,
        payload: state,
    }),
    setCommentForm: (state) => ({
        type:    types.SET_COMMENT_FORM,
        payload: state,
    }),
    setCommentAccess: (state) => ({
        type:    types.SET_COMMENT_ACCESS,
        payload: state,
    }),
    loadData: (state) => ({
        type:    types.SET_LOAD_DATA,
        payload: state,
    }),
    setUserData: (state) => ({
        type:    types.SET_USER_DATA,
        payload: state,
    }),
    setNotification: (state) => ({
        type:    types.SET_NOTIFICATION,
        payload: state,
    }),
};
