import React from 'react';
import { AtsNames } from '../../../instruments/utils';

function Atc(props) {
    const { fieldKey, item } = props;

    return (
        <div>
            { AtsNames[ item[ fieldKey ] ] }
        </div>
    );
}

export default Atc;
