import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import AccountUsers from '../Users';
import Single from '../Users/single';


export const UsersRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <AccountUsers
                        { ...props }
                    />
                }
                path = { book.admin.users }
            />
            <Route
                exact
                element = {
                    <Single
                        { ...props }
                    />
                }
                path = { book.admin.usersSingle }
            />
        </Routes>
    );
};
