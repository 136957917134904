import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, Chip, Box } from '@mui/material';
import  { inputStyles, hoverAnimateLong }  from './style';

export const MultiSelectV2 = (props) => {
    const { valueField = [], nameField, list, setFieldValue, placeholder,  label,
        isFilter } = props;
    const [ value, setValue ] = useState([]);
    const [ val, setVal ] = useState('');
    const [ hover, setHover ] = useState('');

    const handleChange = (selectedValue) => {
        const selectedItem = valueField
            ? valueField.filter((el)=>el)
                .find((item) => item === selectedValue) : null;
        if (selectedItem) {
            setHover(selectedValue);
        } else {
            setHover('');
            const newValue = [ ...value, selectedValue ];
            setValue(newValue);
            setVal(selectedValue);
            setFieldValue(nameField, newValue);
        }
    };
    const handleRemove = (valueToRemove) => {
        const newValue = value.filter((item) => item !== valueToRemove);
        setValue(newValue);
        setFieldValue(nameField, newValue);
    };

    useEffect(() => {
        setValue(valueField);
    }, [ valueField ]);

    const listJSX = list ? list.map((item) => {
        let show = true;
        if (item.isFilter) {
            show =  !!isFilter;
        }

        return item && item.value && show ? (
            <MenuItem
                key = { item.value }
                value = { item.value }
                onClick = {  () => handleChange(item.value) }>
                {item.label}
            </MenuItem>
        ) : null;
    }) : null;


    const selectedJSX = value.length > 0 ? value.map((item) => {
        return item ? (
            <Chip
                key = { item }
                label = { list.find((el) => el.value === item)?.label || '' }
                sx = { item === hover ? hoverAnimateLong : {} }
                onDelete = { () => handleRemove(item) }
            />
        ) : null;
    }) : null;

    return listJSX ? (
        <Box>
            <FormControl
                size = 'small'
                sx = { inputStyles }>
                <Select
                    maxHeight = { '300px' }
                    name = { nameField }
                    placeholder = { placeholder || label }
                    renderValue = { () => null }
                    value = { val }>
                    {listJSX}
                </Select>
            </FormControl>
            {value ? (
                <Box sx = {{ marginTop: '5px' }}>{selectedJSX}</Box>
            ) : null}
        </Box>
    ) : null;
};
