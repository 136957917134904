import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { api } from '../../../Api';
import  { searchListStyles, closeButtonStyles, listItemStyles, listItemHoverStyles, inputWrapStyles, inputStyles }  from './style';
import debounce from 'lodash.debounce'; // Імпорт debounce

export const SearchV2 = (props) => {
    const { valueField = { value: '', label: '' }, nameField, setFieldValue, type = 'author', placeholder = '', multi = false, label = '', clear = false, dataSearch } = props;
    const [ list, setList ] = useState([]);
    const [ val, setVal ] = useState(valueField.label);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ typeLocal, setTypeLocal ] = useState(type);

    const searchRef = useRef(null);
    const setValue = (item) => {
        const name = multi ? item.label : nameField;
        setFieldValue(name, item);
        setVal(item.label);
        setList([]);
        setIsOpen(false);
    };

    const fetchSearchResults = async (value) => {
        const param = {
            search: value,
            type:   typeLocal,
            type3:  typeLocal,
            ...dataSearch,
        };

        const data = await api.postData(param, '/getListSearch');
        if (data) {
            setList(data);
            setIsOpen(true);
        }
    };
    useEffect(() => {
        // Якщо valueField змінилось, і це значення пусте або відрізняється від поточного val
        if (valueField !== val && (valueField === '' || valueField !== val)) {
            setVal(valueField.label);
        }
        setTypeLocal(type);
    }, [ valueField, type ]);

    useEffect(() => {
        if (clear) {
            setVal('');
        }
    }, [ clear ]);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setVal(inputValue);
    };
    const debouncedGetData = useRef(debounce((value) => {
        if (value.length >= 2) {
            fetchSearchResults(value);
        }
    }, 400)).current; // Використовуємо debounce для відправки запиту з затримкою

    useEffect(() => {
        if (val !== valueField && isOpen) {
            debouncedGetData(val); // Викликаємо debouncedGetData замість getData
        }
    }, [ val ]);

    const handleClick = () => {
        fetchSearchResults('');
    };

    const handleListClick = (item) => {
        setValue(item);
    };

    const handleMouseEnter = (index) => {
        setList((prevList) => {
            const updatedList = [ ...prevList ];
            updatedList[ index ].hover = true;

            return updatedList;
        });
    };

    const handleMouseLeave = (index) => {
        setList((prevList) => {
            const updatedList = [ ...prevList ];
            updatedList[ index ].hover = false;

            return updatedList;
        });
    };

    const handleClose = () => {
        setIsOpen(false);
        if (valueField && valueField.value) {
            setFieldValue(nameField, { value: '', label: '' });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const listJSX = isOpen && list && list.length > 0 && (
        <Box sx = { searchListStyles }>
            {list.map((item, index) => (
                <Box
                    key = { index }
                    style = {{
                        ...listItemStyles,
                        ...item.hover && listItemHoverStyles,
                    }}
                    onClick = { () => handleListClick(item) }
                    onMouseEnter = { () => handleMouseEnter(index) }
                    onMouseLeave = { () => handleMouseLeave(index) }>
                    {item.label}
                </Box>
            ))}
        </Box>
    );

    return (
        <Box
            ref = { searchRef }
            style = { inputWrapStyles }>
            <TextField
                inputProps = {{ autoComplete: "off"  }}
                name = 'search'
                placeholder = { placeholder || label }
                size = 'small'
                sx = { inputStyles }
                type = 'text'
                value = { val || '' }
                variant = 'outlined'
                onChange = { handleChange }
                onClick = { handleClick }
            />
            {listJSX && (
                <>
                    <IconButton
                        style = { closeButtonStyles }
                        onClick = { handleClose }>
                        <FontAwesomeIcon
                            icon = { faTimes }
                            style = {{ height: 11 }}
                        />
                    </IconButton>
                    {listJSX}
                </>
            )}
        </Box>
    );
};
