
const listing = {};

listing.list = {
    managerId: {
        type:   'text',
        title:  'Менеджер',
        width:  '10',
        filter: 'SearchV2',
    },
    companyId: {
        type:   'text',
        title:  'Компанія',
        width:  '10',
        filter: 'SearchV2',
    },
    comment: {
        type:  'text',
        title: 'Коментар',
        width: '20',
    },
    phoneId: {
        type:  'text',
        title: 'Телефон',
        width: '10',
    },
    serviceId: {
        type:   'text',
        title:  'Послуга',
        width:  '10',
        filter: 'Select',


    },
    salePrice: {
        type:  'text',
        title: 'Сума транзакції',
        width: '5',
        sort:  true,

    },
    buyPrice: {
        type:  'text',
        title: 'Сума закупки',
        width: '5',
        sort:  true,

    },
    survisorCompanyProfit: {
        type:  'text',
        title: 'Премія менеджера',
        width: '5',
        sort:  true,
    },
    survisorPostmanProfit: {
        type:  'text',
        title: 'Премія сюр.',
        width: '5',
        sort:  true,
    },
    parnerCompanyProfit: {
        type:  'text',
        title: 'Премія партнера.',
        width: '5',
        sort:  true,
    },
    profit: {
        type:  'text',
        title: 'Профіт',
        width: '5',
        sort:  true,

    },
    createdAt: {
        type:   'date',
        title:  'Дата',
        width:  '10',
        filter: 'DateField',
        sort:   true,
    },
};

export { listing };
