import React, { useState } from 'react';
import { api } from '../../../Api';
import { Button } from '@mui/material';
import { ModalResult } from '../jsx/modal';

function Import(props) {
    const {  name, apiUrl = '/import_mor' } = props;
    const [ isSend, setIsSend ] = useState(false);
    const [ text, setText ] = useState({});


    const sendData = async () => {
        setIsSend(true);

        const response = await api.postFile({}, apiUrl);
        if (response && response.status) {
            setText({ success: 'Ok' });
        } else {
            setText({ success: 'No data' });
        }
        setIsSend(false);
        window.location.reload();
    };

    return (
        <>
            <ModalResult
                isSend = { isSend }
                result = { text }
                setSending = { setIsSend }
            />
            <Button
                variant = 'contained'
                onClick = { () => sendData() }>
                {name ? name : 'Import'}
            </Button>

        </>
    );
}

export default Import;
