import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {  Box, Button, IconButton } from '@mui/material';
import * as fieldForm from './export';
import  { formRowFlex }  from './style';
import { Add } from '@mui/icons-material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import moment from 'moment';

import  { IconS }  from '../ListConstructor/style';

const selectState = (state) => ({
    userData: state.swapi.userData,
});
export const ListArr = (props) => {
    const state = useSelector(selectState);
    const  userData  = state.userData;
    const { nameField, setFieldValue, valueField, list, CustomComponents } = props;
    const [ data, setData ] = useState([]);
    const deleteItem = (i) => {
        if (data && data.length > 0) {
            const newData = data.filter((el, k) => k !== i);
            setData(newData);
            setFieldValue(nameField, newData);
        }
    };
    useEffect(() => {
        setData(valueField);
    }, [ valueField ]);

    const setChangeData = (name, val, i, isAdd = false) => {
        let newData = [ ...data ];
        if (name && !isAdd) {
            const param = name.split('|');
            if (newData[ param[ 0 ] ]) {
                newData[ param[ 0 ] ][ param[ 1 ] ] = val;
            } else {
                newData[ param[ 0 ] ] = {
                    [ param[ 1 ] ]: val,
                };
            }
            newData[ param[ 0 ] ].isChange = true;

            setData(newData);
            setFieldValue(nameField, newData);
        } else if (isAdd) {
            newData.unshift(val);
            setData(newData);
            setFieldValue(nameField, newData);
        }
    };

    const add = () => {
        const newData =  [ ...data ];
        const newItem = {};
        if (list && list.length > 0) {
            for (const a of list) {
                newItem[ a.field ] = null;
            }
            if (newData.length > 0) {
                newData.unshift(newItem);
            } else {
                newData.push(newItem);
            }
            setData(newData);
        }
    };


    const headerJSX = data && data.length > 0 ? list.map((f, i) => {
        const width = f.width ?  f.width : 95 / list.length;
        let show = true;
        if (f.roles && show) {
            show = f.roles.includes(userData.role);
        }

        return show ? (
            <Box
                key = { i }
                sx = {{ width: width + '%' }}>
                {f.label || f.field }
            </Box>

        ) : null;
    }) : null;


    const dataJSX = data && data.length > 0 ? data.map((it, i) => {
        const fieldDataJSX = list && list.length > 0 ? list.map((f) => {
            let Component = fieldForm[ f.component ] ? fieldForm[ f.component ] : '';
            if (f.type === 'custom' && CustomComponents && CustomComponents[ f.field ]) {
                Component = CustomComponents[  f.field ];
            }
            const width = f.width ?  f.width : 95 / list.length;
            let show = true;
            if (f.roles && show) {
                show = f.roles.includes(userData.role);
            }
            const val =  f.component === 'DateField'  && it[ f.field ] ?  moment(it[ f.field ]).format('DD.MM.yyyy') : it[ f.field ];

            return show ? (
                <Box
                    key = { i + '|' + f.field }
                    sx = {{ width: `${width}%` }}>
                    {!f.onlyRead  ? (
                        <Component
                            { ...props }
                            { ...f.props ? f.props : {} }
                            index = { i }
                            item = { it }
                            list = { f.list ? f.list : [] }
                            nameField = { i + '|' + f.field }
                            placeholder = {  f.label || f.field }
                            setFieldValue = { setChangeData }
                            valueField = { it[ f.field ] ? it[ f.field ] : '' }
                        />) : (<div>{val}</div>)}

                </Box>

            ) : null;
        }) : '';

        return (
            <Box
                key = { i }
                sx = { formRowFlex }>
                {fieldDataJSX}
                { userData.role === 'admin' ? (
                    <IconButton
                        style = {{ color: 'red' }}
                        onClick = { () => deleteItem(i) }>
                        <DeleteIcon />
                    </IconButton>
                ) : null}
            </Box>

        );
    }) : null;

    return (
        <>

            { userData.role === 'admin' ? (
                <Button
                    color = { 'warning' }
                    variant = 'contained'
                    onClick = { () => {
                        add();
                    } }>

                    <Add sx = { IconS } /> {' Додати'}
                </Button>
            ) : null}
            <div style = {{ padding: '5px 15px', display: 'flex', justifyContent: 'space-between', marginTop: '20px', background: '#fff', boxShadow: '0px 5px 15px -10px', borderRadius: '10px' }}>
                {headerJSX}
                { userData.role === 'admin' ? (
                    <div style = {{ width: '36px' }}></div>
                ) : null}
            </div>
            <div style = {{ padding: '5px 15px' }}>
                {dataJSX}
            </div>

        </>
    );
};
