import React, { lazy, Suspense } from 'react';
import { AppBar } from '@mui/material';
import { styled } from '@mui/system';

const HeaderLine = lazy(() => import('./headerLine'));

const StyledAppBar = styled(AppBar)`
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background:#fff;
`;

function Header() {
    return (
        <StyledAppBar
            elevation = { 0 }
            position = 'static'>
            <Suspense fallback = { <div>Loading HeaderLine...</div> }>
                <HeaderLine />
            </Suspense>
        </StyledAppBar>
    );
}


export default Header;
