import React, { useState, useRef } from 'react';
import { api } from '../../../Api';
import { Button } from '@mui/material';
import { ModalResult } from '../jsx/modal';
import * as XLSX from 'xlsx';
function ImportCsv(props) {
    const { type, name, apiUrl = '/phone/import' } = props;
    const [ isSend, setIsSend ] = useState(false);
    const [ text, setText ] = useState({});
    const fileInputRef = useRef(null);
    const generateCsv = (data) => {
        const xlsData = [];

        data.forEach((item) => {
            const { number, provider, status, clientId } = item;

            xlsData.push({
                Provider:    provider || '',
                'Client ID': clientId || '',
                Number:      number || '',
                Status:      status || '',
            });
        });

        // Створюємо нову книгу Excel
        const worksheet = XLSX.utils.json_to_sheet(xlsData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Генеруємо файл Excel
        const xlsFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Створюємо Blob для файлу
        const blob = new Blob([ xlsFile ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Створюємо посилання для завантаження файлу
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'output.xlsx';  // Вказуємо ім'я файлу
        document.body.appendChild(link);
        link.click();  // Автоматичне клікування для завантаження
        document.body.removeChild(link);  // Видаляємо посилання після завантаження
    };

    const importFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setIsSend(true);

        const response = await api.postFile(formData, apiUrl);
        if (response && type !== 'phone') {
            generateCsv(response);
            setText({ success: 'Ok' });
        } else {
            setText({ success: 'No data' });
        }

        setIsSend(false);
        fileInputRef.current.value = null;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[ 0 ];
        if (file) {
            importFile(file);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <ModalResult
                isSend = { isSend }
                result = { text }
                setSending = { setIsSend }
            />
            <input
                accept = '.xlsx,.xls,.csv'
                ref = { fileInputRef }
                style = {{ display: 'none' }}
                type = 'file'
                onChange = { handleFileChange }
            />
            <Button
                color = { type === 'phone' ? 'primary' : 'error' }
                variant = 'contained'
                onClick = { handleButtonClick }>
                {name}
            </Button>
        </>
    );
}

export default ImportCsv;
