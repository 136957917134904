/* eslint-disable no-extra-parens */
import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import en from 'date-fns/locale/en-GB';
registerLocale('en', en);

export const DateField = (props) => {
    const { valueField, nameField, setFieldValue, showStartDate, disablePrevDates, beforeAllow, label = '', isTime, isListing } = props;

    function getDateTime(now, interval) {
        return new Date(Math.round(new Date(now).getTime()
        / (interval * 1000)) * (interval * 1000));
    }
    let start = showStartDate ? getDateTime(new Date(), 1800) : '';
    start = valueField && valueField !== '' ? new Date(valueField) : start;
    const [ startDate, setStartDate ] = useState(start);

    const onChange = (date) => {
        if (date) {
            setFieldValue(nameField, date.toString());
        } else {
            setFieldValue(nameField, '');
        }
        setStartDate(date);
    };

    useEffect(() => {
        if (valueField) {
            setStartDate(new Date(valueField));
        } else {
            setStartDate('');
        }
    }, [ valueField ]);

    const style = {
        position: !isListing ? 'relative' : '',
    };

    // Визначення мінімальної та максимальної дати для вибору
    let minDate = null;

    if (disablePrevDates) {
        minDate = new Date();
    } else if (beforeAllow) {
        minDate = new Date(Date.now() - (beforeAllow * 24 * 60 * 60 * 1000));
    }

    return (
        <div style = { style }>
            <DatePicker
                dateFormat = { props.format ? props.format : 'dd.MM.yyyy HH:mm' }
                locale = 'en'
                minDate = { minDate }
                placeholderText = { label }
                selected = { startDate }
                showMonthYearPicker = { !!props.showMonthYearPicker }
                showTimeSelect = { isTime }
                timeFormat = 'HH:mm'
                onChange = { (date) => onChange(date) }
            />
        </div>
    );
};
