import React, { useState, useEffect } from 'react';
import { Search }  from './search';
import { Chip, Box } from '@mui/material';
import { hoverAnimate }  from './style';


export const MultiSearch = (props) => {
    const {  valueField = [], nameField,  setFieldValue, type, placeholder, label,
        dataSearch } = props;
    const [ value, setValue ] = useState([]);

    useEffect(() => {
        setValue(valueField);
    }, [ valueField, valueField.length ]);

    const handleChange = (name, val) => {
        const selectedItem = valueField ? valueField.find((item) => item.value === val) : null;
        if (selectedItem) {
            const newValue = [ ...value ];
            newValue.map((el)=> {
                el.hover =  el.value === selectedItem.value;

                return el;
            });
            setValue(newValue);
        } else {
            const newValue = [ ...value, { label: name, value: val }];
            setValue(newValue);
            setFieldValue(nameField, newValue);
        }
    };

    const handleRemove = (valueToRemove) => {
        const newValue = value.filter((item) => item.value !== valueToRemove);
        setValue(newValue);
        setFieldValue(nameField, newValue);
    };


    const selectedJSX = value && value.length > 0 ? value.map((item) => {
        return item && item.value ? (
            <Chip
                key = { item.value }
                label = { item.label }
                sx = { item.hover ? hoverAnimate : {} }
                onDelete = { () => handleRemove(item.value) }
            />
        ) : null;
    }) : null;

    return  (

        <>
            <Search
                clear
                multi
                dataSearch = { dataSearch }
                nameField = { nameField }
                placeholder = { placeholder || label }
                setFieldValue  = { handleChange }
                type = { type }
            />
            {value ? (
                <Box sx = {{ marginTop: '5px' }}>{selectedJSX}</Box>
            ) : null}
        </>
    );
};
