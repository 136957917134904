import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Divider, Box, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Save, Cancel } from '@mui/icons-material';
import  { deleteItem  }  from '../ListConstructor/utils';
export const BtnAll = (props) => {
    const { item, componentName, setHidden, urlApi, userData, type, edit,
        setIsSave, setEdit, setIsAdd, field, changeStatus } = props;
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ data, setData ] = useState(null);

    useEffect(() => {
        const _data = {
            id:        item._id,
            setHidden: setHidden,
            urlApi:    urlApi,
            userData:  userData,
            type:      type,
        };
        setData({ ..._data });
    }, [ item._id, urlApi, userData, type ]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const saveStyles = {
        color: '#008000',
    };

    const cancelStyles = {
        color: 'rgb(25, 118, 210)',
    };
    const btnStyle = { display: 'block', width: "100%", marginBottom: '5px', padding: '3px 2px 1px' };
    const hrefEdit = '/' + componentName + '/' + item._id;
    let href = '';
    const btnJSX = field.values && field.values.length > 0 ? field.values.map((el) => {
        let renderData = '';
        let h = hrefEdit;
        if (el === 'edit') {
            h = hrefEdit;
        }
        if (el === 'link') {
            href = item.link && item.link.includes('http') > -1 ? item.link : '/' + item.link;
            h = href;
        }

        if (el === 'status') {
            renderData = (
                <Button
                    color = { !item[ el ] ? 'warning' : 'error' }
                    sx = { btnStyle }
                    variant = { 'contained' }
                    onClick = { () => {
                        changeStatus(!item[ el ], el);
                    } }>
                    { !item[ el ] ? 'enable' : 'disable' }
                </Button>
            );
        }

        if (el !== 'delete' && el) {
            renderData = (
                <Button
                    href = { h ? h : '' }
                    rel = 'noreferrer'
                    sx = { btnStyle }
                    target = '_blank'
                    variant = { 'outlined' }>
                    {el}
                </Button>
            );
        }
        if (el === 'delete') {
            renderData = (
                <Button
                    color = { 'error' }
                    sx = { btnStyle }
                    variant = { 'contained' }
                    onClick = { () => {
                        deleteItem(data, setHidden);
                        handleClose();
                        setIsAdd(false);
                    } }>
                    {el}
                </Button>
            );
        }

        return (
            <React.Fragment key = { el }>
                { renderData }
            </React.Fragment>
        );
    }) : (
        <>
            <IconButton
                aria-controls = 'btn-menu'
                aria-haspopup = 'true'
                aria-label = 'more'
                size = 'small'
                onClick = { handleClick }>
                <MoreVertIcon />
            </IconButton>
            <Menu
                keepMounted
                anchorEl = { anchorEl }
                id = 'btn-menu'
                open = { Boolean(anchorEl) }
                onClose = { handleClose }>

                <MenuItem
                    component = 'a'
                    href = { hrefEdit }
                    target = '_blank'>Edit
                </MenuItem>
                <MenuItem
                    component = 'a'
                    href = { href }
                    target = '_blank'>Link
                </MenuItem>
                <Divider sx = {{ background: '#e3eefa', opacity: '1' }}></Divider>
                <MenuItem onClick = { () => {
                    deleteItem(data, setHidden);
                    handleClose();
                    setIsAdd(false);
                } }>Delete
                </MenuItem>
            </Menu>
        </>
    );

    return  (
        <>

            { edit === item._id ? (
                <>
                    <Box>
                        <IconButton
                            size = 'small'
                            sx = {{ padding: 0 }}
                            onClick = { () => setIsSave(true) }>
                            <Save

                                style = { saveStyles }
                            />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton
                            size = 'small'
                            sx = {{ padding: 0 }}
                            onClick = { () => {
                                setEdit(null);
                                if (typeof setIsAdd === 'function') {
                                    setIsAdd(false);
                                }
                            } }>
                            <Cancel style = { cancelStyles } />
                        </IconButton>
                    </Box>
                </>
            ) : (<>{btnJSX}</>)

            }
        </>
    );
};
