import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../Api';
import { Box } from '@mui/material';
import ItemRender from './itemRender';
import striptags from 'striptags';

const selectState = (state) => ({
    userData: state.swapi.userData,
});
function ListItem(props) {
    const { urlApi, fields, data, edit, setEdit, type, setIsAdd, isAdd, setNewItem } = props;
    const [ saveData, setSaveData ] = useState({});
    const [ isSave, setIsSave ] = useState(false);
    const [ item, setItem ] = useState(data);
    const [ hidden, setHidden ] = useState(false);
    const [ error, setError ] = useState('');
    const [ errors, setErrors ] = useState({});
    const [ dataSearch, setDataSearch ] = useState({});
    const [ userData, setUserData ] = useState({});
    const state = useSelector(selectState);
    const  _userData  = state.userData;
    useEffect(() => {
        setUserData(_userData);
    }, [  ]);

    const refItem = useRef(null);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(function () {
                setError('');
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [ error ]);

    useEffect(() => {
        setIsSave(false);
        setSaveData({});
    }, [ edit ]);


    const onChange = (name, val) => {
        const key = name.split('|');
        const nameField = key[ 0 ];
        const newData = { ...saveData };
        newData[ nameField ] = val;

        setSaveData(newData);
    };

    const save = async (obj) => {
        obj._id = data._id && data._id !==  'new'  ? data._id : '';
        const objSave = { ...item, ...obj };
        const result = await api.postData({ data: objSave, type: type }, urlApi + '/save/' + edit);
        if (result.error) {
            setError(result.error);
        }
        setEdit(null);
        setIsSave(false);
        setSaveData({});

        if (!obj._id && result._id) {
            setNewItem(result);
        } else {
            console.log('Item updated', result, { ...data, ...result });
            setItem({ ...item, ...result });
        }
    };

    useEffect(() => {
        setItem({ ...item, ...saveData });
    }, [ data ]);


    const validate = (name, type) => {
        const validData = { ...data, ...saveData };
        let valid = true;
        if (!validData[ name ]) {
            valid = false;
        }
        if (type !== 'date' && validData[ name ] && typeof validData[ name ] === 'object') {
            const findContent = Object.keys(validData[ name ]).find((el) => striptags(validData[ name ][ el ]).split(/\s+/)
                .join('') !== '');

            valid = !!findContent;
        }

        return valid;
    };

    useEffect(() => {
        if (isSave) {
            const newErrors = {};
            for (const name of Object.keys(fields)) {
                if (fields[ name ].require) {
                    const valid = validate(name, fields[ name ].type);
                    if (!valid) {
                        newErrors[ name ] = true;
                    }
                }
            }
            if (Object.keys(newErrors).length === 0) {
                save(saveData);
                if (typeof setIsAdd === 'function') {
                    setIsAdd(false);
                }
            }

            setErrors(newErrors);
        }

        setIsSave(false);
    // eslint-disable-next-line
  }, [isSave]);

    const fieldsJSX = item
        ? Object.keys(fields).map((key) => {
            let show = true;
            if (fields[ key ].roles && show) {
                show = fields[ key ].roles.includes(userData.role);
            }
            if (fields[ key ].onlyNew && !isAdd) {
                show = false;
            }

            if (!show) {
                return null;
            }
            const disabled = fields[ key ].disabled;
            if (disabled) {
                for (const disabledKey of Object.keys(disabled)) {
                    if (disabled[ disabledKey ] === true) {
                        switch (typeof data[ disabledKey ]) {
                            case 'object':
                                if (data[ disabledKey ].length > 0) {
                                    return null;
                                }
                                break;
                            default:
                                return null;
                        }
                    }
                }
            }

            let textAlign = 'center';
            if (Number(fields[ key ].width) > 9) {
                textAlign = 'left';
            }
            let bg = '';
            if (item[ key + '_bg' ]) {
                bg =  item[ key + '_bg' ];
            }


            return (
                <td
                    className = { errors[ key ] ? 'error' : '' }
                    key = { key }
                    style = {{ textAlign: textAlign, background: bg }}>
                    <ItemRender
                        { ...props }
                        dataSearch = { dataSearch }
                        field = { fields[ key ] }
                        fieldKey = { key }
                        item = {{ ...item, ...saveData }}
                        setDataSearch = { setDataSearch }
                        setHidden = { setHidden }
                        setIsSave = { setIsSave }
                        setItem = { setItem }
                        onChange = { onChange }
                    />
                </td>
            );
        })
        : null;

    let bg = item.bg ?  item.bg : '';

    return (
        <>
            {error && (
                <Box>
                    {error}
                </Box>
            )}

            <tr
                className = { ` ${error ? 'error' : ''} ${hidden ? 'hiddenItem' : ''} ${
                    edit === item._id ? 'active' : ''
                }` }
                id = {  edit === item._id  ? edit : '' }
                ref = { refItem }
                style = {{ background: bg }}
                onDoubleClick = { () => setEdit(item._id) }>
                {fieldsJSX}

            </tr>
        </>
    );
}

export default ListItem;
