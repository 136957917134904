import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

const checkboxStyles = {
    checked: {
        color: '#1976d2', // Колір для галочки
    },
    unchecked: {
        color: '#bdbdbd', // Колір для прочерку
    },
    indeterminate: {
        color: '#bdbdbd', // Колір для невизначеного стану
    },
};

function ThreeStateCheckbox({ filter, name, setFilter }) {
    const [ checked, setChecked ] = useState(null);

    const handleChange = () => {
        let newChecked = null;

        if (checked === true) {
            newChecked = false;
            setFilter({ ...filter, [ name ]: newChecked });
        } else if (checked === false) {
            newChecked = null;
            const newFilter = { ...filter };
            delete newFilter[ name ];
            setFilter({ ...newFilter });
        } else {
            newChecked = true;
            setFilter({ ...filter, [ name ]: newChecked });
        }

        setChecked(newChecked);
    };

    let checkboxStyle = '';

    if (checked === true) {
        checkboxStyle = checkboxStyles.checked;
    } else if (checked === false) {
        checkboxStyle = checkboxStyles.unchecked;
    } else {
        checkboxStyle = checkboxStyles.indeterminate;
    }

    return (
        <div style = {{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
                checked = { checked === true }
                indeterminate = { checked === null }
                style = { checkboxStyle }
                onChange = { handleChange }
            />
        </div>
    );
}

export default ThreeStateCheckbox;
