import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import AccountService from '../Service';

export const ServiceRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <AccountService
                        { ...props }
                    />
                }
                path = { book.admin.service }
            />
        </Routes>
    );
};
