import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

export const InputCheckbox = (props) => {
    const {
        valueField,
        nameField,
        setFieldValue,
        index = '',
        edit,
        placeholder = '',
    } = props;

    const [ value, setValue ] = useState('');

    useEffect(() => {
        setValue(valueField);
    }, [ valueField ]);

    const onChange = (event) => {
        const newValue = event.target.checked;
        setValue(newValue);
        setFieldValue(nameField, newValue, index);
    };

    useEffect(() => {
        setValue(valueField);
    }, [ valueField ]);

    return (
        <FormControlLabel
            control = {
                <Checkbox
                    checked = { !!value }
                    disabled = { !edit }
                    id = { nameField }
                    sx = {
                        {
                            margin:  '0',
                            padding: '0',
                        }
                    }
                    title = { placeholder }
                    onChange = { onChange }
                />
            }
            sx = {
                {
                    margin:  '0',
                    padding: '0',
                }
            }
        />
    );
};
