/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { api } from '../../../Api';
import { useSelector } from 'react-redux';
const selectState = (state) => ({
    userData: state.swapi.userData,
});

export const Balance = (props) => {
    const state = useSelector(selectState);
    const  userData  = state.userData;

    const { fieldKey, item, setItem, isSingle } = props;
    const [ open, setOpen ] = useState(false);
    const [ type, setType ] = useState('');
    const [ balance, setBalance ] = useState(0);

    const [ amount, setAmount ] = useState('');
    const [ comment, setComment ] = useState('');

    const handleClickOpen = (type) => {
        setType(type);
        setOpen(true);
    };
    useEffect(() => {
        setBalance(item[ fieldKey ]);
    }, [ item[ fieldKey ] ]);

    const handleClose = () => {
        setOpen(false);
        setAmount('');
    };

    const handleOk = async () => {
        handleClose();
        if (typeof item[ fieldKey ] === 'undefined') {
            item[ fieldKey ] = 0;
        }
        const result = await api.postData({
            field:      fieldKey,
            type:       type,
            amount:     Number(amount),
            comment:    comment,
            balace_old: item[ fieldKey ] || 0,
        }, `/changeBalance/${item._id}`);
        if (typeof result?.data?.[ fieldKey ] === 'number' && result.data[ fieldKey ] > -100000 && setItem) {
            setItem(result?.data);
        }
    };
    const h2Text = fieldKey === 'balance' ? 'Баланс послуг' : 'Баланс телефонії';

    return (
        <div style = {{ padding: isSingle ? '20px' : 0 }}>
            {isSingle ? (
                <h2 style = {{ textAlign: 'center' }}>
                    {h2Text}
                </h2>
            ) : null}
            {item._id  && userData.role === 'admin' ? (

                <div style = {{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        color = 'success'
                        variant = 'contained'
                        onClick = { () => handleClickOpen('add') }>
                        Поповнити
                    </Button>
                </div>
            ) : '' }

            <div style = {{  textAlign: "center" }}>{balance || 0}</div>

            <Dialog
                open = { open }
                onClose = { handleClose }>
                <DialogTitle>{type}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        label = 'Введіть суму'
                        margin = 'dense'
                        type = 'number'
                        value = { amount }
                        variant = 'outlined'
                        onChange = { (event) => setAmount(event.target.value) }
                    />
                    <TextField
                        autoFocus
                        fullWidth
                        label = 'Коментар'
                        margin = 'dense'
                        value = { comment }
                        variant = 'outlined'
                        onChange = { (event) => setComment(event.target.value) }
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color = 'primary'
                        onClick = { handleClose }>
                        Скасувати
                    </Button>
                    <Button
                        color = 'primary'
                        onClick = { handleOk }>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
