const listing = {};

listing.list = {
    name: {
        type:      'text',
        title:     'Імя',
        width:     '8',
        filter:    'Input',
        component: 'Input',
    },
    telegram_id: {
        type:      'text',
        title:     'Телеграм ID',
        width:     '8',
        filter:    'Input',
        component: 'Input',
    },
    role: {
        type:      'text',
        title:     'Роль',
        width:     '5',
        filter:    'Select',
        component: 'Select',
        //   role_edit: [ 'admin' ],
    },
    company_id: {
        type:      'arrSelect',
        title:     'Компанії',
        width:     '15',
        filter:    'SearchV2',
        component: 'MultiSearch',
    },
    password: {
        type:      'text',
        title:     'Пароль',
        width:     '5',
        filter:    'Input',
        component: 'Input',
        onlyNew:   true,
    },
    email: {
        type:      'text',
        title:     'Email',
        width:     '7',
        filter:    'Input',
        component: 'Input',
    },

    delete: {
        type:   'delete',
        values: [ 'delete' ],
        width:  '1',
    },
};


listing.sendpulse_status = [
    { label: 'не перевірений', value: 0 },
    { label: 'дійсний', value: 1 },
    { label: 'непідтвердженний', value: 2 },
    { label: 'недійсний', value: 3 },
];

export { listing };
