/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import   Phone   from '../Phone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Chip, Box } from '@mui/material';
import { api } from '../../../Api';
import { MultiSearch } from '../FormConstructor/multiSearch';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';


const selectState = (state) => ({
    userData: state.swapi.userData,
});

export const Phones = (props) => {
    const state = useSelector(selectState);

    const  userData  = state.userData;

    const { fieldKey, item, setItem, isSingle } = props;
    const [ open, setOpen ] = useState(false);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ isList, setIslist ] = useState(false);

    useEffect(() => {
        setSelectedItems(item.phones);
    }, [ item.phones ]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const onChange = (name, value) => {
        console.log(value);
        setSelectedItems(value);
        const newItem = { ...item };
        newItem[ fieldKey ] = value;
        console.log(newItem);
        setItem(newItem);
    };
    const handleSave = () => {
        confirmAlert({
            title:            'Підтвердження змін',
            message:          '',
            overlayClassName: 'confirmAlertOverlay',
            buttons:          [
                {
                    label:   'Yes',
                    onClick: async () => {
                        console.log('Delete item', item._id);
                        const result = await api.postData({
                            field: fieldKey,
                            selectedItems,
                            id:    item._id,
                        }, `/changePhones/${item._id}`);
                        if (result?.data && setItem) {
                            setItem(result.data);
                        }
                        handleClose();
                    },
                    className: 'confirm',
                },
                {
                    label:     'No',
                    className: 'cancel',
                },
            ],
        });
    };

    return (
        <div style = {{ padding: isSingle ? '20px' : 0 }}>
            { userData.role !== 'admin' ? (
                <h3>Підключенні номери</h3>
            ) : null}
            <Box sx = {{ marginBottom: '20px' }}>
                {item.phones && item.phones.length > 0
                    && item.phones.map((phoneObj, index) => (
                        <Chip
                            key = { index }
                            label = { phoneObj.label }
                            sx = {{ marginRight: '5px', marginBottom: '5px' }}
                        />
                    ))
                }
            </Box>
            { userData.role === 'admin' ? (
                <Button
                    color = 'primary'
                    variant = 'contained'
                    onClick = { handleClickOpen }>
                    Управління номерами
                </Button>
            ) : null}


            <Dialog
                maxWidth = { 'lg' }
                open = { open }
                width = { 1200 }
                onClose = { handleClose }>
                <DialogTitle>Додати номер телефону</DialogTitle>
                <DialogContent sx = {{ width: '1200px', height: '600px'  }} >
                    <MultiSearch
                        nameField = 'phones'
                        placeholder = 'Виберіть телефон'
                        setFieldValue = { onChange }
                        type = 'phonesFree'  // передайте дані для пошуку
                        valueField = { selectedItems }
                    />
                    {isList ? (
                        <Phone
                            isCompanyModule
                            limit = { 5 }
                        />
                    ) : (
                        <Button
                            color = 'primary'
                            variant = 'contained'
                            onClick = { ()=>setIslist(true) }>
                            створити
                        </Button>
                    )  }

                </DialogContent>
                <DialogActions>
                    <Button
                        color = 'secondary'
                        onClick = { () => handleClose() }>
                        Відмінити
                    </Button>
                    <Button
                        color = 'primary'
                        onClick = { () => handleSave() }>
                        Зберегти
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
