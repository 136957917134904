// eslint-disable-line
import React, { useState, useEffect } from 'react';

import { api } from '../../../Api';
import  FormConstructor  from '../FormConstructor';

function Single(props) {
    const urlArr = window.location.pathname.split('/');
    const id = urlArr[ urlArr.length - 1 ];
    const [ data, setData ] = useState(null);


    const fields = {};
    fields.email = {
        type:      'text',
        label:     'Full name',
        component: 'Input',
    };
    fields.password = {
        type:      'text',
        label:     'password',
        component: 'Input',
    };
    fields.image = {
        type:      'img',
        component: 'UploadImage',
    };

    const getData = async () => {
        const data = await api.postData({ type: 'user' }, '/user/' + id);
        if (data) {
            setData(data);
        }

        if (id === 'new') {
            const defaultData = {};
            setData(defaultData);
        }
    };
    useEffect(() => {
        getData();
    }, []);


    return data && Object.keys(data).length  > 0 ? (
        <div className = 'account edit_block seo_single'>
            <div className = 'form_data'>
                <FormConstructor
                    data = { data }
                    fields = { fields }
                    maxLength = { 500 }
                    type = { 'user' }
                    urlApi = { '/users/save/' + id }
                    { ...props }
                />
            </div>

        </div>
    ) : null;
}

export default Single;
