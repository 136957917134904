import React, { useState, useEffect } from 'react';
import { api } from '../../../Api';
import * as fieldForm from './export';
import striptags from 'striptags';
import history from '../../Router/history';
import { Modal, Button, Box } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import  {  modalSmallStyle, formSingle, formRow, formSingleCustom, formRowCustom, formCustom, onlyRead }  from './style';
import { useParams } from 'react-router-dom';

function FormConstructor(props) {
    const { fields, urlApi, type,  CustomComponents = {},
        parentFormId, setSaveForm, id,  setCallBack, userData, listSelect } = props;
    const { match_id } = useParams();

    const [ isSend, setIsSend ] = useState(false);
    const [ data, setData ] = useState(null);
    const [ isSaved, setIsSaved ] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ dataSearch, setDataSearch ] = useState({});
    const [ urlSave, setUrlSave ] = useState(urlApi);
    const [ idLocal, setId ] = useState(id);
    const [ modalText, setModalText ] = useState('');

    const validate = (name, type) => {
        const validData = data;
        let valid = true;
        if (!validData[ name ]) {
            valid = false;
        }
        if (type !== 'date' && validData[ name ] && typeof validData[ name ] === 'object') {
            const findContent = Object.keys(validData[ name ]).find((el) => striptags(validData[ name ][ el ]).split(/\s+/)
                .join('') !== '');

            valid = !!findContent;
        }

        return valid;
    };

    const  onChange = (name, val) => {
        const key = name.split('|');
        const nameField = key[ 0 ];
        const newData = { ...data };
        if (typeof dataSearch[ name ] !== "undefined") {
            dataSearch[ name ] = val;
            setDataSearch(dataSearch);
        }
        Object.keys(fields).forEach((fieldName) => {
            if (fields[ fieldName ] && fields[ fieldName ].dataSearch) {
                if (fields[ fieldName ].dataSearch && typeof fields[ fieldName ].dataSearch[ nameField ] !== 'undefined') {
                    newData[ fieldName ] = null;
                }
            }
        });

        newData[ nameField ] = newData[ nameField ] ? newData[ nameField ] : '';
        newData[ nameField ] = val;

        setData(newData);
        if (typeof setCallBack === 'function') {
            setCallBack(newData);
        }

        return '';
    };

    const saveApi = async (newData) => {
        const updData = {
            _id:  data._id || 'new',
            data: newData,
            type: type,
        };

        if (parentFormId) {
            updData.parentFormId = parentFormId;
        }
        updData.isSingle =  true;
        const result = await api.postData(updData, urlSave);
        if (result.error) {
            let newErrors = {};
            for (const el of Object.keys(result.error)) {
                newErrors[ el ] = true;
            }
            setErrors(newErrors);
            setIsSend(false);
        } else {
            if (setSaveForm && typeof setSaveForm === 'function') {
                setSaveForm(result);
            }
            setData(result);
            setIsSend(false);
            setIsSaved(true);
            setTimeout(() => {
                setIsSaved(false);
            }, 1000);
            if (idLocal === 'new' && result && result._id) {
                const url = `${window.location.pathname.split('/new')[ 0 ]}/${result._id}`;
                history.push(url);
                let inputString = urlSave;
                let outputString = inputString.replace(/\bnew\b/g, result._id);
                setUrlSave(outputString);
                setId(result._id);
            } else if (!idLocal && match_id && result && result._id) {
                const url = `${window.location.pathname.split('/create/' + match_id)[ 0 ]}/${result._id}`;
                history.push(url);
                let inputString = urlSave;
                let outputString = inputString.replace(/\bnew\b/g, result._id);
                setUrlSave(outputString);
                setId(result._id);
            }
        }


        return !!result;
    };

    const saveData = (isGlobal = false)=>{
        setIsSend(true);
        const newErrors = {};
        for (const name of Object.keys(fields)) {
            if (fields[ name ].require) {
                const valid = validate(name, fields[ name ].type);
                if (!valid) {
                    newErrors[ name ] = true;
                }
            }
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const _data = { ...data };
            if (isGlobal) {
                _data.isGlobal = true;
            }
            saveApi(_data);
        } else {
            setIsSend(false);
        }
    };


    useEffect(() => {
        for (const key of Object.keys(fields)) {
            if (fields[ key ].dataSearch) {
                for (const el of Object.keys(fields[ key ].dataSearch)) {
                    let val =  data[ el ]  && data[ el ].value ? data[ el ].value  : '';
                    val =  !val && data[ el ] ? data[ el ]  : val;
                    dataSearch[ el ] = val;
                }
            }
        }

        setDataSearch(dataSearch);
    }, [  ]);

    useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    }, [ props.data ]);

    const fieldsJSX = data && dataSearch ? Object.keys(fields).map((key) => {
        const isReadonly = fields[ key ].onlyRead;

        let newData = { ...data };
        if (fields[ key ].type === 'empty') {
            return (
                <Box
                    key = { key }
                    sx = {{ ...formRow,  ...formCustom, width: fields[ key ].width + '%' }}>
                </Box>
            );
        }

        if (fields[ key ].type === 'custom' && CustomComponents && CustomComponents[ key ]) {
            const CustomComponent = CustomComponents[ key ];

            return (
                <Box
                    className = {  errors[ key ]  ? 'error' : '' }
                    key = { key }
                    sx = {{ ...formRow,  ...formCustom, width: fields[ key ].width + '%' }}>
                    {!isReadonly ? (
                        <CustomComponent
                            isSingle
                            data = { newData }
                            dataSearch = { dataSearch }
                            fieldKey = { key }
                            item = { newData }
                            key = { key }
                            listSelect = { listSelect }
                            setItem = { setData }
                            shortCodes = { props.shortCodes ? props.shortCodes : [] }
                            onChange = { onChange }

                        />
                    ) : (
                        <Box sx = { onlyRead }>
                            <h3>{fields[ key ].label}</h3>
                            {newData[ key ]}
                        </Box>
                    )}
                </Box>

            );
        }
        if (typeof newData[ key ] === 'undefined') {
            newData[ key ] =  '';
        }
        const Component = fieldForm[ fields[ key ].component ];
        let fieldsByType = '';
        if (Component && newData && newData[ key ] !== 'undefined') {
            const field = fields[ key ];
            const isError = errors[ key ] ? 'error' : '';
            const maxLength = fields[ key ].maxLength || 150;
            const maxHeight = fields[ key ].maxHeight || 700;
            const isNumber = !!fields[ key ].isNumber;
            const isTime = !!fields[ key ].isTime;
            const type = fields[ key ].typeSearch || key;
            const placeholder = fields[ key ].label || key;
            const list = fields[ key ].list || [];

            fieldsByType = (
                <Box
                    className = { isError }
                    key = { key }>
                    {!isReadonly ? (
                        <Component
                            { ...props }
                            edit
                            isSingle
                            dataSearch = { dataSearch }
                            field = { field }
                            isNumber = { isNumber }
                            isTime = { isTime }
                            item = { newData }
                            list = { list }
                            maxHeight = { maxHeight }
                            maxLength = { maxLength }
                            nameField = { key }
                            placeholder = { placeholder }
                            setFieldValue = { onChange }
                            setItem = { setData }
                            type = { type }
                            valueField = { newData?.[ key ] || '' }
                        />
                    ) : (
                        <Box sx = { onlyRead }>{newData[ key ]}</Box>
                    )}
                </Box>
            );
        }


        const width = fields?.[ key ]?.width || '100';

        return (
            <Box
                key = { key }
                sx = { parentFormId ? { ...formRowCustom, width: width + '%' }  : { ...formRow, width: width + '%' } }>
                <Box sx = {{ whiteSpace: 'nowrap' }}>
                    { fields[ key ].label ? fields[ key ].label : key }
                </Box>

                <Box sx = {{ width: width ? width : '100' }}>
                    {fieldsByType}
                </Box>
            </Box>
        );
    }) : null;

    useEffect(() => {
        let modalText = '';
        if (isSaved) {
            modalText = 'Save';
        }
        if (isSend) {
            modalText = 'PLEASE WAIT!';
        }

        setModalText(modalText);
    }, [ isSaved, isSend ]);

    return (
        <>
            <Modal
                open = { !!isSend || !!isSaved  } >
                <Box

                    sx = {{ ...modalSmallStyle, color: isSaved ? '#2e7d32' : '' }}>
                    <h2 >{ modalText }</h2>
                </Box>
            </Modal>
            <Box
                className = { parentFormId ? 'formSingleCustom'  : 'singleForm' }
                sx = { parentFormId ? formSingleCustom  : formSingle }>
                {fieldsJSX}
                <Box sx = {{ display: 'flex', width: '100%', justifyContent: 'center', margin: 'auto' }}>
                    {userData?.role !== 'client' ? (
                        <Button
                            disabled = { isSend }
                            style = { type !== 'eventItem' ? {
                                position:       'fixed',
                                bottom:         '20px',
                                right:          '20px',
                                borderRadius:   '15%',
                                minWidth:       '40px',
                                height:         '40px',
                                display:        'flex',
                                justifyContent: 'center',
                                alignItems:     'center',
                                padding:        '0',
                                zIndex:         '100000',
                            } : {} }
                            variant = 'contained'
                            onClick = { ()=>saveData() }>
                            { type === 'eventItem' ? 'save' : (<SaveIcon style = {{ margin: '0 4px' }} />)}

                        </Button>
                    ) : ''}

                </Box>
            </Box>
        </>
    );
}

export default FormConstructor;
