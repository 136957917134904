import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import Postman from '../Postman';
export const PostmanRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <Postman
                        { ...props }
                    />
                }
                path = { book.admin.postman }
            />
        </Routes>
    );
};
