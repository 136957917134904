import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../instruments';
import Login from '../Account/Login/login';
import Home from '../Home';

export const GlobalRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <Home
                        { ...props }
                    />
                }
                path = { book.home }
            />
            <Route
                exact
                element = {
                    <Login
                        { ...props }
                    />
                }
                path = { book.login }
            />
        </Routes>
    );
};
