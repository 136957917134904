import React, { useState, useEffect } from 'react';
import { FormControl, Select as SelectComponent, MenuItem, InputLabel, Box } from '@mui/material';

import  { inputStyles }  from './style';

export const Select = (props) => {
    const {
        valueField,
        nameField,
        list,
        setFieldValue,
        label,
        placeholder,
        isSingle,
        setDefaultFilter,
        clear = false,
        isFilter,
    } = props;

    const [ value, setValue ] = useState(valueField || '');
    const [ listOpt, setList ] = useState(list || '');

    const handleChange = (newValue) => {
        setValue(newValue);
        if (!newValue && typeof setDefaultFilter === 'function') {
            setDefaultFilter(false);
        }
        setFieldValue(nameField, newValue);
    };
    useEffect(() => {
        if (clear) {
            setValue('');
        }
    }, [ clear ]);
    useEffect(() => {
        if (valueField === '' && value !== '') {
            setValue('');
        } else {
            setValue(valueField);
        }
    }, [ valueField ]);


    useEffect(() => {
        setList(list);
    }, [ list ]);
    const optionJSX
    = listOpt
    && listOpt.map((el) => {
        let show = true;
        if (el.isFilter) {
            show =  !!isFilter;
        }

        return el.label && show ? (
            <MenuItem
                key = { el.value }
                sx = { inputStyles }
                value = { el.value }
                onClick = {  () => handleChange(el.value) }>
                {el.label}
            </MenuItem>
        ) : null;
    });

    return (
        <Box>
            <FormControl
                fullWidth
                size = 'small'
                variant = 'outlined'>
                {!isSingle ? (<InputLabel id = { `${nameField}-label` }>{label || placeholder}</InputLabel>
                ) : null}

                <SelectComponent
                    defaultChecked = { placeholder }
                    labelId = { `${nameField}-label` }
                    placeholder = { placeholder || label }

                    sx = { inputStyles }
                    value = { value }>
                    <MenuItem
                        sx = { inputStyles }
                        value = ''
                        onClick = {  () => handleChange('') }>{label || placeholder}
                    </MenuItem>
                    {optionJSX}

                </SelectComponent>
            </FormControl>
        </Box>
    );
};
