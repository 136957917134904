import React from 'react';
import { Button } from '@mui/material';
import { api } from '../../../Api';

export const BtnService = (props) => {
    const { data, placeholder, item, nameField, setItem } = props;
    const sendData = async () => {
        const [ index ] = nameField.split('|');
        const params = {
            index:      Number(index),
            item,
            company_id: data._id,
        };
        const result = await api.postData(params, '/writeOffService/');
        if (result?.data?._id && setItem) {
            setItem((prev)=>{
                return {
                    ...prev,
                    balance_history: result.data.balance_history,
                    balance:         result.data.balance,
                };
            });
        }
    };

    return (
        <Button
            variant = { 'contained' }
            onClick = { ()=>sendData() }>
            {placeholder}
        </Button>
    );
};
